//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2024 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
// Styles
import styles from './KasiButtonClipboard.module.scss'
import sharedStyles from '../KasiButton/KasiButton.module.scss';

//This component was created to allow users to copy the support email to their clipboard, but can be retooled for any sort of copy to clipboard functionality.
//It's base is a copy of the KasiButton component.

//import KasiButtonClipboard from './pages/Dashboard/Components/KasiButtonClipboard/KasiButtonClipboard';
//<KasiButtonClipboard label="Copy Email To Clipboard" clipboard="support@opconnect.com"/>

const KasiButtonClipboard = ({ 
  label="Copy Email",                   // Pass a label to the button
  id="",                                // Pass an id to the button
  className="",                         // Pass a className to the button
  clipboard = "support@opconnect.com",  // Pass the email to copy here. (If you want to change the default email here is where you do it)
  buttonStyle="blue",                   // Pass buttonStyle="blue red green" to change the button color
  type="button",                        // Pass type="submit" to the button to handle forms, or pass type="button"
  kasibeta="true"                       // Pass kasibeta="false" to use the legacy MyOpConnect style
}) => {
  const [showSuccessIcon, setShowSuccessIcon] = useState(false);
  const [showFailureIcon, setShowFailureIcon] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await navigator.clipboard.writeText(clipboard);
      setShowSuccessIcon(true); // Show success icon after successful copy
      setTimeout(() => setShowSuccessIcon(false), 2000); // Hide success icon after 2 seconds
    } catch (error) {
      setShowFailureIcon(true); // Show failure icon after failed copy
      setTimeout(() => setShowFailureIcon(false), 2000); // Hide fail icon after 2 seconds
    }
  };

  //Pass the string "blue red green" into the buttonStyle parameter to access the 3 different colors
  let buttonStyleClass = "KasiButton" + buttonStyle

  //The SVGs are embedded in this component since they aren't used anywhere else.
  let KasiClipboardIconAdd =
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="white" viewBox="0 0 24 24">
      <path d="M19 3H14.82C14.4 1.84 13.3 1 12 1S9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.11 3.9 21 5 21H19C20.11 21 21 20.11 21 19V5C21 3.9 20.11 3 19 3M12 3C12.55 3 13 3.45 13 4S12.55 5 12 5 11 4.55 11 4 11.45 3 12 3M7 7H17V5H19V19H5V5H7V7M13 12H16V14H13V17H11V14H8V12H11V9H13V12Z" />
    </svg>
  
  let KasiClipboardIconSuccess =
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="#00FF00" viewBox="0 0 24 24">
      <path d="M19,3H14.82C14.4,1.84 13.3,1 12,1C10.7,1 9.6,1.84 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M7,7H17V5H19V19H5V5H7V7M7.5,13.5L9,12L11,14L15.5,9.5L17,11L11,17L7.5,13.5Z" />
    </svg>
  
  let KasiClipboardIconFailure =
  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="red" viewBox="0 0 24 24">
    <path d="M19 3H14.82C14.4 1.84 13.3 1 12 1S9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.11 3.9 21 5 21H19C20.11 21 21 20.11 21 19V5C21 3.9 20.11 3 19 3M12 3C12.55 3 13 3.45 13 4S12.55 5 12 5 11 4.55 11 4 11.45 3 12 3M19 19H5V5H7V7H17V5H19V19M15.54 10.88L13.41 13L15.54 15.12L14.12 16.54L12 14.41L9.88 16.54L8.47 15.12L10.59 13L8.47 10.88L9.88 9.47L12 11.59L14.12 9.47L15.54 10.88Z" />
  </svg>

  return (
    <>
    {kasibeta 
    ?
      <button
        id={id}
        className={`${sharedStyles.KasiButtonMedium} ${sharedStyles[buttonStyleClass]} ${className}`}
        onClick={handleClick}
        type={type}
      >
        <span className={styles.KasiClipboardIcon}>
          {label}
          {" "}
          {showSuccessIcon 
            ? KasiClipboardIconSuccess
            : showFailureIcon 
            ? KasiClipboardIconFailure
            : KasiClipboardIconAdd
          }
        </span>
      </button>   
    :
      <Button
        id={id}
        className={`${styles.MyOpConnectClipboardButton} ${className}`}
        onClick={handleClick}
        type={type}
      >
        <span className={styles.KasiClipboardIcon}>
          {label}
          {" "}
          {showSuccessIcon 
            ? KasiClipboardIconSuccess
            : showFailureIcon 
            ? KasiClipboardIconFailure
            : KasiClipboardIconAdd
          }
        </span>
      </Button>   
      }
    </>
  );
};

export default KasiButtonClipboard;